import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../../middleware/apis';

const slice = createSlice({
	name: 'user-list',
	initialState: {
		userList: [],
		loading: false
	},
	reducers: {
		requested: (users, action) => {
			users.loading = true;
		},
		success: (users, action) => {
			users.userList = action.payload;
			users.loading = false;
		},
		failed: (users, action) => {
			users.userList = action.payload;
			users.loading = false;
		}
	}
});

const { requested, success, failed } = slice.actions;
export default slice.reducer;

export const getUserList = (data) =>
	apiCallBegan({
		url: '/user/get-users',
		method: 'POST',
		data,
		onStart: requested.type,
		onSuccess: failed.type,
		onFailed: success.type
	});
