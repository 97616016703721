import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../../middleware/apis'

const slice = createSlice({
    name: 'enable-disable-notification',
    initialState: {
        notificationStatus: null,
        loading: false,
    },
    reducers: {
        requested: (notification) => {
            notification.loading = true
        },
        success: (notification, action) => {
            notification.notificationStatus = action.payload;
            notification.loading = false
        },
        failed: (notification, action) => {
            notification.notificationStatus = action.payload;
            notification.loading = false
        }
    }
})

const { requested, success, failed } = slice.actions
export default slice.reducer

export const setEnableDisableNotification = (token, item) => apiCallBegan({
    url: '/notification/enable-disable-notification',
    method: 'POST',
    token,
    data: { _id: item._id, enable: item.enable },
    onStart: requested.type,
    onSuccess: failed.type,
    onFailed: success.type
})