import axios from 'axios';
import * as actions from './apis';
import { baseUrl } from './url';
import { logoutUser, replaceToken } from '../store/login';
import { useHistory } from 'react-router';

const api =
  ({ dispatch, getState }) =>
  next =>
  async action => {
    if (action.type !== actions.apiCallBegan.type) return next(action);

    const { url, method, data, onStart, onSuccess, onFailed, onReset, onUploadProgress, onUpdateToken, onCancel } = action.payload;

    if (onStart) dispatch({ type: onStart });
    next(action);

    try {
      const {
        credentials: { credentials },
      } = getState();

      if (onUpdateToken) dispatch({ type: onUpdateToken, payload: data });

      if (onReset) dispatch({ type: onReset, payload: null });
      let headers = {
        'Content-Type': 'application/json',
      };

      if (credentials && credentials.data && credentials.data.token) {
        headers = {
          'Content-Type': 'application/json',
          token: credentials && credentials.data && credentials.data.token,
        };
      }

      const response = await axios.request({
        baseURL: baseUrl,
        url,
        method,
        data,
        headers,
        cancelToken: onCancel ? onCancel.token : undefined,
        onUploadProgress: onUploadProgress ? progress => onUploadProgress(progress.loaded / progress.total) : undefined,
      });

      // For General
      // dispatch(actions.apiCallSuccess(response.data))
      // For Specific

      if (onSuccess) dispatch({ type: onSuccess, payload: response.data });
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        // history.push('/')
        dispatch(logoutUser());
      }

      // For General
      // dispatch(actions.apiCallFailed(error.message))

      // For Specific
      if (onFailed) dispatch({ type: onFailed, payload: error.message });
    }
  };

export default api;
